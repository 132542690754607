.what-we-do-section {
  padding: 50px 0;
  @media (max-width: 768px) {
    padding: 40px 0;
  }
  .heading-title {
    @media (max-width: 768px) {
      text-align: center;
      margin: 0 0 24px;
    }
    svg {
      @media (max-width: 768px) {
        max-width: 100px;
      }
    }
    h2 {
      font-size: 56px;
      color: #fff;
      font-weight: 700;
      line-height: 62px;
      margin: 8px 0 0;
      @media (max-width: 1280px) {
        font-size: 48px;
        line-height: 58px;
      }
      @media (max-width: 992px) {
        font-size: 40px;
        line-height: 50px;
      }
      @media (max-width: 768px) {
        font-size: 30px;
        line-height: 38px;
      }
      span {
        color: #4a85f1;
      }
    }
  }
  .col-md-4 {
    &:last-child {
      .card {
        @media (max-width: 768px) {
          border: 0;
          margin-bottom: 0 !important;
        }
      }
    }
  }
  .card {
    border-radius: 0;
    border: 0;
    @media (max-width: 768px) {
      padding: 0 0 20px;
      border-bottom: 1px solid rgba(217, 217, 217, 0.1);
    }
    .card-header {
      border: 0;
      background-color: transparent;
      @media (max-width: 767px) {
        img {
          max-width: 52px;
        }
      }
      h3 {
        font-size: 24px;
        font-weight: 600;
        color: #fff;
        margin: 8px 0 0;
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
    }
    .card-content {
      p {
        font-size: 16px;
        color: #959cb1;
        margin: 0;
        max-width: 75%;
        @media (max-width: 1280px) {
          max-width: 90%;
        }
        @media (max-width: 768px) {
          max-width: 100%;
        }
      }
    }
  }
}

.banner-section {
  .banner-image {
    text-align: right !important;
  }
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 40px 0;
  }
  .banner-content {
    h1 {
      font-size: 62px;
      font-weight: 700;
      color: #fff;
      line-height: 84px;
      background: linear-gradient(
        92.66deg,
        #fffad1 -3.82%,
        #ffe5dc 32.23%,
        #ffc8fa 65.78%,
        #accdff 103.24%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media (max-width: 1440px) {
        font-size: 52px;
        line-height: 62px;
      }
      @media (max-width: 1200px) {
        font-size: 48px;
        line-height: 56px;
      }
      @media (max-width: 767px) {
        font-size: 32px;
        line-height: 42px;
      }
    }
    p {
      font-size: 20px;
      max-width: 75%;
      color: #99a4b6;
      @media (max-width: 767px) {
        font-size: 16px;
        max-width: 100%;
      }
    }
    .custom-gradient-btn {
      position: relative;
      background: transparent;
      border: 0;
      font-size: 18px;
      font-weight: 600;
      border-radius: 4px;
      padding: 13px 10px 12px 30px;
      color: #fff;
      margin: 20px 0;
      &:hover {
        background: linear-gradient(
          86.77deg,
          #f1ba67 2.72%,
          #7af4f4 55.28%,
          #fe886e 99.45%
        );
        color: #2e2e2e;
        border-radius: 40px;
      }
      &:before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 40px;
        padding: 2px;
        background: linear-gradient(
          86.77deg,
          #f1ba67 2.72%,
          #7af4f4 55.28%,
          #fe886e 99.45%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask-composite: add, add;
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }
  }
}

.building-section {
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 0px 0;
  }
  .heading-title {
    text-align: center;
    h2 {
      font-size: 36px;
      color: #fff;
      display: inline-block;
      font-weight: 700;
      line-height: 42px;
      text-align: center;
      position: relative;
      margin: 0 auto 30px;
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 38px;
      }

      &:before {
        background-color: #71b4a5;
        content: "";
        display: block;
        height: 3px;
        width: 75px;
        margin-bottom: 5px;

        @media (max-width: 767px) {
          margin-left: 10px;
        }
      }
      &:after {
        background-color: #9b4747;
        content: "";
        display: block;
        position: absolute;
        right: -8px;
        bottom: -20px;
        height: 3px;
        width: 75px;
        margin-bottom: 0.25em;

        @media (max-width: 767px) {
          right: 10px;
        }
      }
    }
  }
  .rounded-section {
    margin: 30px 0 0;
    @media (max-width: 767px) {
      margin: 20px 0 0;
    }
    ul {
      margin: 0 -10px;
      li {
        display: inline-block;
        list-style: none;
        padding: 0 10px;
        @media (max-width: 767px) {
          flex: 0 0 33.33%;
        }
        .outer-box {
          background: #1b202b;
          border-radius: 30px;
          height: 120px;
          width: 120px;
          margin: 0 0 20px;
          @media (max-width: 1400px) {
            height: 100px;
            width: 100px;
          }
          @media (max-width: 1200px) {
            height: 80px;
            border-radius: 20px;
            width: 80px;
          }
          @media (max-width: 767px) {
            height: 65px;
            width: 100%;
            border-radius: 15px;
            margin: 8px 0;
            padding: 0 10px;
          }
          img {
            max-width: 65px;
            @media (max-width: 1200px) {
              max-width: 50px;
            }
            @media (max-width: 767px) {
              max-width: 40px;
            }
          }
        }
      }
    }
  }
}

.how-it-works-section {
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 0px 0;
  }
  &.benefit-section {
    @media (max-width: 767px) {
      padding: 30px 0;
    }
    .outer-works-container {
      &::before {
        display: none;
      }
      ul {
        li {
          .card-outer {
            .card-inner {
              p {
                max-width: 94%;
              }
              .custom-button {
                background: #111325;
                border-radius: 14px;
                color: #fff;
                font-weight: 400;
                font-size: 16px;
                font-family: "Source Sans 3", sans-serif;
                padding: 14px 15px;
                border: 1px solid #fff;
                &:hover {
                  background: #fff;
                  color: #111325;
                }
              }
            }
          }
        }
      }
    }
  }
  .heading-title {
    h2 {
      font-size: 36px;
      color: #fff;
      font-weight: 700;
      line-height: 42px;
      text-align: center;
      margin: 0 0 20px;
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
    p {
      font-size: 24px;
      color: #959cb1;
      max-width: 40%;
      margin: 0 auto;
      text-align: center;
      @media (max-width: 1100px) {
        max-width: 80%;
      }
      @media (max-width: 767px) {
        font-size: 18px;
        max-width: 100%;
      }
    }
  }
  .outer-works-container {
    &:before {
      background: url("../../Assets/Images/background-dots.svg") no-repeat;
      background-position: center;
      content: "";
      width: 100%;
      display: block;
      height: 100%;
      position: absolute;
      top: -30px;
      @media (max-width: 767px) {
        display: none;
      }
    }
    ul {
      position: relative;
      z-index: 99;
      li {
        max-width: 300px;
        flex: 0 0 300px;
        list-style: none;
        margin: 0 30px;
        @media (max-width: 1200px) {
          margin: 0 20px;
          flex: 0 0 270px;
          max-width: 270px;
        }
        @media (max-width: 768px) {
          margin: 0 0 20px;
          flex: 0 0 100%;
          max-width: 100%;
        }
        .card-outer {
          background: #161a22;
          border: 1px solid rgba(228, 237, 255, 0.15);
          border-radius: 30px;
          padding: 20px;
          .number-icon {
            @media (max-width: 767px) {
              position: absolute;
              right: 30px;
              max-width: 32px;
            }
          }
          .card-inner {
            h3 {
              font-size: 24px;
              font-weight: 700;
              color: #fff;
              @media (max-width: 1024px) {
                font-size: 20px;
              }
            }
            p {
              font-size: 16px;
              color: #9da6b6;
              max-width: 90%;

              @media (max-width: 1200px) {
                min-height: 100px;
              }
              @media (max-width: 768px) {
                min-height: auto;
              }
              @media (max-width: 767px) {
                max-width: 100%;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

.key-features-section {
  padding: 50px 0;
  background: url(../../Assets/Images/feature-background.svg) no-repeat bottom
    left;
  background-size: 100px;

  @media (min-width: 992px) {
    img {
      display: none;
    }
  }

  @media (max-width: 767px) {
    padding: 40px 0;
  }

  .header {
    margin-bottom: 30px;
  }

  .heading-title {
    h2 {
      font-size: 56px;
      font-weight: 600;
      max-width: 50%;
      position: relative;
      color: #fff;

      @media (max-width: 992px) {
        font-size: 40px;
        line-height: 50px;
      }
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
  }
  .feature-slider {
    padding: 0 20px;
    @media (max-width: 767px) {
      padding: 0;
    }
    .feature-outer-div {
      background: #161a22;
      border: 1px solid rgba(228, 237, 255, 0.15);
      border-radius: 30px;
      padding: 20px;
      h3 {
        font-size: 22px;
        font-weight: 600;
        color: #fff;
      }
      p {
        font-size: 16px;
        min-height: 100px;
        color: #9da6b6;
        @media (max-width: 767px) {
          min-height: auto;
        }
      }
    }
  }
  .slick-arrow {
    top: auto;
    bottom: -30px;
    &.slick-next {
      right: -25px;
      left: 50%;
      margin-left: 50px;
      z-index: 99;
      @media (max-width: 767px) {
        right: 0;
        left: auto;
        margin-left: 0;
      }
      &:before {
        display: none;
      }
    }
    &.slick-prev {
      left: auto;
      right: 50%;
      z-index: 99;
      margin-right: 45px;
      @media (max-width: 767px) {
        right: auto;
        left: 0;
        margin-right: 0;
      }
      &:before {
        display: none;
      }
    }
  }
  .slick-dots {
    li {
      margin: 0 5px;
      display: inline-table;
      button {
        font-size: 24px;
        line-height: 0;
        display: block;
        width: 30px;
        height: 6px;
        padding: 0;
        &:before {
          content: "";
          background: rgba(255, 255, 255, 0.3);
          height: 6px;
          width: 30px;
        }
      }
      &.slick-active {
        button {
          &:before {
            background: #fff;
          }
        }
      }
    }
  }
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

#path-second path {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
#path-second {
  mix-blend-mode: screen;
}

.path-upper,
#path-first {
  mix-blend-mode: color-dodge;
}

.twelve h1 {
  position: relative;
  padding: 0;
  margin: 0;
  color: white;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  letter-spacing: 1px;
  width: 160px;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  padding-bottom: 20px;
  margin-bottom: 20px;

  font-size: 36px;
  color: #fff;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 30px;
  }
}
.twelve h1:before {
  background-color: #71b4a5;
  content: "";
  display: block;
  height: 3px;
  width: 75px;
  margin-bottom: 5px;

  @media (max-width: 767px) {
    margin-left: 10px;
  }
}
.twelve h1:after {
  background-color: #9b4747;
  content: "";
  display: block;
  position: absolute;
  right: -8px;
  bottom: 0;
  height: 3px;
  width: 75px;
  margin-bottom: 0.25em;

  @media (max-width: 767px) {
    right: 10px;
  }
}

.community-section {
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 40px 0;
  }
  .accordion-outer {
    background: #161a22;
    border: 1px solid rgba(228, 237, 255, 0.15);
    border-radius: 30px;
    padding: 20px;
    .accordion-item {
      background: transparent;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-radius: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      .accordion-body {
        padding: 0;
        p {
          color: #fff;
          padding: 0 10px;
          margin: 0;
        }
      }
      .accordion-header {
        border: 0;
        .accordion-button {
          padding: 0;
          box-shadow: none;
          background: transparent;
          font-size: 16px;
          color: #fff;
          &:after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
          }
          &:not(.collapsed)::after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
          }
          img {
            max-width: 52px;
          }
        }
      }
    }
  }
  .heading-title {
    .custom-button {
      background: #209de3;
      border-radius: 40px;
      color: #fff;
      padding-left: 22px;
      padding-right: 10px;
      text-decoration: none;
      font-size: 16px;
      &.ghost {
        background: #6a76f6;
      }
      @media (max-width: 640px) {
        font-size: 14px;
        padding-left: 16px;
        padding-right: 16px;
        margin: 0 5px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      &:hover {
        background: #fff;
        color: #4a85f1;
      }
    }
  }
  .listing-community {
    li {
      list-style: none;
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding: 15px 15px;
      @media (max-width: 1024px) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .listing-card {
        background: #161a22;
        border: 1px solid rgba(228, 237, 255, 0.15);
        border-radius: 30px;
        padding: 20px;
        img {
          @media (max-width: 1400px) {
            width: 66px;
          }
        }
        .content-div {
          h3 {
            font-size: 24px;
            font-weight: 700;
            color: #fff;
            @media (max-width: 1400px) {
              font-size: 20px;
            }
          }
          p {
            font-size: 16px;
            color: #fff;
            @media (max-width: 1400px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.partners-section {
  .partner-listing {
    li {
      max-width: 25%;
      flex: 0 0 25%;
      padding: 12px 12px;
      list-style: none;
      @media (max-width: 992px) {
        max-width: 50%;
        flex: 0 0 50%;
        padding: 10px 10px;
      }
      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(3),
      &:nth-child(8),
      &:nth-child(7) {
        .partner-container {
          img {
            width: 240px;
            @media (max-width: 992px) {
              width: 190px;
            }
          }
        }
      }
      .partner-container {
        background: rgba(255, 255, 255, 0.04);
        border-radius: 16px;
        min-height: 100px;
        img {
          width: 200px;
          @media (max-width: 992px) {
            width: 150px;
          }
        }
      }
    }
  }
}

.partner-logo {
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 40px 0;
  }
  .heading-title {
    text-align: center;
    h2 {
      font-size: 36px;
      color: #fff;
      display: inline-block;
      font-weight: 700;
      line-height: 42px;
      text-align: center;
      position: relative;
      margin: 0 auto 30px;
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
  }
  .partner-listing {
    li {
      // flex: 0 0 33.33%;
      // max-width: 33.33%;
      padding: 15px 15px;
      .partner-outer {
        background: #161a22 url("../../Assets/Images/bg-texture.svg") no-repeat;
        border-radius: 30px;
        padding: 24px 24px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);
        .partner-header {
          padding: 0 0 10px;
          margin: 0 0 10px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        }
        .partner-content {
          .content-inner {
            min-height: 94px;
            h3 {
              font-size: 18px;
              color: #fff;
            }
            p {
              font-size: 16px;
              color: rgba(206, 217, 234, 0.6);
            }
          }
        }
      }
    }
    .slick-arrow {
      top: auto;
      bottom: -30px;
      &.slick-next {
        right: -25px;
        left: 50%;
        margin-left: 50px;
        z-index: 99;
        @media (max-width: 767px) {
          right: 0;
          left: auto;
          margin-left: 0;
        }
        &:before {
          display: none;
        }
      }
      &.slick-prev {
        left: auto;
        right: 50%;
        z-index: 99;
        margin-right: 45px;
        @media (max-width: 767px) {
          right: auto;
          left: 0;
          margin-right: 0;
        }
        &:before {
          display: none;
        }
      }
    }
    .slick-dots {
      li {
        margin: 0 5px;
        display: inline-table;
        padding: 0;
        button {
          font-size: 24px;
          line-height: 0;
          display: block;
          width: 30px;
          height: 6px;
          padding: 0;
          &:before {
            content: "";
            background: rgba(255, 255, 255, 0.3);
            height: 6px;
            width: 30px;
          }
        }
        &.slick-active {
          button {
            &:before {
              background: #fff;
            }
          }
        }
      }
    }
  }
}
