footer {
  background: #161b24;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: 40px 0;
  @media (max-width: 767px) {
    padding: 40px 0;
  }
  .copyright-div {
    border-top: 1px solid #353945;
    p {
      font-size: 12px;
      color: #777e90;
    }
  }
  .logo-div {
    p {
      font-size: 16px;
      max-width: 75%;
      color: #fff;
      margin: 8px 0 12px;
      @media (max-width: 767px) {
        font-size: 14px;
        max-width: 100%;
      }
    }
    ul {
      li {
        list-style: none;
        margin-right: 8px;
      }
    }
  }
  .footer-content {
    @media (max-width: 767px) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 18px;
      color: #fff;
    }
    p {
      font-size: 14px;
      color: #959cb1;
    }
    ul {
      max-width: 50%;
      width: 100%;
      position: relative;
      padding-right: 15px;
      @media (max-width: 767px) {
        max-width: 100%;
        padding-right: 0;
        &::before {
          display: none;
        }
        &:first-child {
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
      }
      &:last-child {
        &:before {
          display: none;
        }
      }
      &:before {
        content: "";
        background: url('data:image/svg+xml,<svg width="3" height="120" viewBox="0 0 3 120" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.12" x="0.986328" width="2" height="119.871" rx="1" fill="url(%23paint0_linear_2565_1440)"/><defs><linearGradient id="paint0_linear_2565_1440" x1="1.98633" y1="0" x2="1.98633" y2="119.871" gradientUnits="userSpaceOnUse"><stop stop-color="%23D9D9D9"/><stop offset="1" stop-color="%23D9D9D9" stop-opacity="0"/></linearGradient></defs></svg>')
          no-repeat;
        display: block;
        position: absolute;
        right: 50px;
        left: auto;
        width: 3px;
        height: 100%;
        @media (max-width: 767px) {
          display: none;
        }
      }
      li {
        list-style: none;
        h4 {
          margin-bottom: 20px;
          font-size: 18px;
          text-transform: uppercase;
          color: #fff;
        }
        a {
          text-decoration: none;
          font-size: 14px;
          display: block;
          margin: 0 0 8px;
          color: #79859a;
          &:hover {
            color: #4a85f1;
          }
        }
      }
    }

    .custom-gradient-btn {
      position: relative;
      background: transparent;
      border: 0;
      font-size: 14px;
      font-weight: 600;
      border-radius: 40px;
      padding: 13px 10px 12px 30px;
      color: #fff;
      margin: 10px 0;
      &:hover {
        background: linear-gradient(
          86.77deg,
          #f1ba67 2.72%,
          #7af4f4 55.28%,
          #fe886e 99.45%
        );
        color: #2e2e2e;
        border-radius: 40px;
      }
      &:before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 40px;
        padding: 2px;
        background: linear-gradient(
          86.77deg,
          #f1ba67 2.72%,
          #7af4f4 55.28%,
          #fe886e 99.45%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask-composite: add, add;
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }
  }
}
