header {
  @media (max-width: 767px) {
    background-color: rgba(29, 34, 46, 0.9);
  }
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  @media (max-width: 767px) {
    .container {
      background-color: rgba(29, 34, 46, 0.9);
    }
  }

  background-color: #11151d;
  transition: 0.2s all ease-in-out;
  &.scrolled {
    background-color: rgba(29, 34, 46, 0.9);
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0;
    z-index: 101;
    transition: 0.2s all ease-in-out;
    .custom-nav {
      border: 0;
      border-radius: 0;
      margin: 0 !important;
      z-index: 99;
    }
  }
  .custom-nav {
    // background-color: rgba(29, 34, 46, 0.9);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    // border-radius: 50px;
    // padding-left: 30px;
    // padding-right: 30px;
    @media (max-width: 767px) {
      border-radius: 0;
      padding-left: 0px;
      padding-right: 0px;
      border: 0;
    }
    .navbar-brand {
      width: 213px;
    }
    .navbar-toggler {
      &:focus {
        box-shadow: none;
      }
      @media (max-width: 992px) {
        position: absolute;
        top: 25px;
        right: 25px;
      }
      &.collapsed {
        .navbar-toggler-icon {
          background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.33333 9C3.59695 9 3 9.59695 3 10.3333C3 11.0697 3.59695 11.6667 4.33333 11.6667H23C23.7364 11.6667 24.3333 11.0697 24.3333 10.3333C24.3333 9.59695 23.7364 9 23 9H4.33333Z' fill='%23777E91'/%3E%3Cpath d='M4.33333 17C3.59695 17 3 17.597 3 18.3333C3 19.0697 3.59695 19.6667 4.33333 19.6667H23C23.7364 19.6667 24.3333 19.0697 24.3333 18.3333C24.3333 17.597 23.7364 17 23 17H4.33333Z' fill='%23777E91'/%3E%3C/svg%3E");
        }
      }
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.32695 6.3268C6.76283 5.89101 7.46947 5.89107 7.90527 6.32695L13.9988 12.4217L20.0949 6.3268C20.5308 5.89101 21.2374 5.89107 21.6732 6.32695C22.109 6.76283 22.1089 7.46947 21.673 7.90527L15.5777 13.9994L21.6731 20.0948C22.109 20.5306 22.109 21.2373 21.6731 21.6731C21.2373 22.109 20.5306 22.109 20.0948 21.6731L14 15.5783L7.90519 21.6731C7.46935 22.109 6.76272 22.109 6.32688 21.6731C5.89104 21.2373 5.89104 20.5306 6.32688 20.0948L12.4211 14.0006L6.3268 7.90512C5.89101 7.46923 5.89107 6.7626 6.32695 6.3268Z' fill='white'/%3E%3C/svg%3E");
      }
    }
    .navbar-nav {
      @media (max-width: 992px) {
        padding: 40px 0 10px;
      }
      a:not(.custom-button, .custom-git) {
        color: #fff;
        position: relative;
        &:last-child {
          &::after {
            display: none;
          }
        }
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 4px;
          border-radius: 4px;
          background-color: #4a85f1;
          bottom: 0;
          left: 0;
          transform-origin: right;
          transform: scaleX(0);
          transition: transform 0.3s ease-in-out;
        }
        &:after {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          background: #fff;
          border-radius: 50px;
          right: -10px;
          top: 20px;

          @media (max-width: 992px) {
            display: none;
          }
        }
        @media (max-width: 992px) {
          background: #161a22;
          border: 1px solid rgba(228, 237, 255, 0.15);
          border-radius: 10px;
          margin: 0 0 16px;
          background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2544_1075)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.7064 11.293C15.8938 11.4805 15.9992 11.7348 15.9992 12C15.9992 12.2652 15.8938 12.5195 15.7064 12.707L10.0494 18.364C9.95712 18.4595 9.84678 18.5357 9.72477 18.5881C9.60277 18.6405 9.47155 18.6681 9.33877 18.6692C9.20599 18.6704 9.07431 18.6451 8.95141 18.5948C8.82852 18.5445 8.71686 18.4703 8.62297 18.3764C8.52908 18.2825 8.45483 18.1708 8.40454 18.048C8.35426 17.9251 8.32896 17.7934 8.33012 17.6606C8.33127 17.5278 8.35886 17.3966 8.41126 17.2746C8.46367 17.1526 8.53986 17.0422 8.63537 16.95L13.5854 12L8.63537 7.05C8.45321 6.8614 8.35241 6.60879 8.35469 6.3466C8.35697 6.0844 8.46214 5.83359 8.64755 5.64818C8.83296 5.46277 9.08377 5.3576 9.34597 5.35532C9.60816 5.35305 9.86076 5.45384 10.0494 5.636L15.7064 11.293Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2544_1075'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")
            no-repeat 97%;
          padding: 20px 20px;
        }
        &:hover {
          color: #4a85f1;
          &:before {
            transform-origin: left;
            transform: scaleX(1);
          }
        }
        &.active {
          color: #4a85f1;
        }
      }
    }
    .inner-custom-nav {
      @media (max-width: 992px) {
        padding: 0;
      }
      @media (max-width: 340px) {
        display: block !important;
        text-align: center;
        .custom-button {
          margin-left: 0 !important;
          margin-top: 10px;
          justify-content: center;
        }
      }
      a:not(.custom-button) {
        color: #fff;
        @media (max-width: 992px) {
          background-image: none;

          border-radius: 40px;
          margin: 0 0;
          background: #242a34;
          padding: 8px;
          font-size: 14px;
          svg {
            max-width: 32px;
            max-height: 32px;
          }
        }
      }
    }
    .custom-button {
      background: #fff;
      border-radius: 40px;
      color: #11151d;
      font-size: 16px;
      line-height: 24px;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 30px;
      }
      &:hover {
        background: #fff;
        color: #4a85f1;
      }
    }
  }
}
