@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@900&family=Source+Sans+3:wght@200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@200;300;400;500;600;700&family=Raleway:wght@900&family=Source+Sans+3:wght@200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Source Sans 3", sans-serif;
  background-color: #11151d !important;
}
img,
svg {
  max-width: 100%;
}
.container {
  @media (max-width: 767px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.top-banner {
  position: relative;
  background-size: cover;
  padding: 8px 0;
  max-height: 60px;
  &:before {
    content: "";
    background: url(./Assets/Images/banner-bg.png)
      no-repeat;
    background-size: 100%;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  p {
    font-size: 28px;
    color: #fff;
    font-weight: 600;
    position: relative;
    @media (max-width: 1200px) {
      font-size: 22px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
    }
    span {
      background: #ff3b20;
      border-radius: 12px;
      font-size: 18px;
      margin: 0 15px;
      padding: 2px 10px;
      @media (max-width: 767px) {
        font-size: 12px;
        margin: 0 6px;
        padding: 2px 6px;
      }
    }
    a {
      font-size: 24px;
      color: #8eb4ff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      @media (max-width: 1200px) {
        font-size: 22px;
      }
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
    img {
      max-width: 40px;
      margin: 0 0 0 10px;

      @media (max-width: 767px) {
        max-width: 28px;
        margin-left: 5px;
      }
    }
  }
}
