.token-distribution-section {
  padding: 70px 0 40px;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
}
// .reviews {
//   padding: 70px 0;
//   @media (max-width: 768px) {
//     padding: 40px 0;
//   }
//   h2 {
//     font-size: 26px;
//     color: #8c9fc5;
//     @media (max-width: 768px) {
//       margin: 30px 0 16px !important;
//       font-size: 18px;
//     }
//   }
//   .img-outer {
//     background-image: url("data:image/svg+xml,%3Csvg width='358' height='101' viewBox='0 0 358 101' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Crect x='0.640625' y='0.835327' width='356.143' height='99' rx='24.5' fill='url(%23paint0_linear_6600_2702)' fill-opacity='0.05'/%3E%3Crect x='0.640625' y='0.835327' width='356.143' height='99' rx='24.5' stroke='url(%23paint1_linear_6600_2702)'/%3E%3Crect x='67.1406' y='14.3353' width='223' height='72' fill='url(%23pattern0)'/%3E%3Cdefs%3E%3Cpattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'%3E%3Cuse xlink:href='%23image0_6600_2702' transform='matrix(0.000524674 0 0 0.00162133 -0.000276911 -0.0901639)'/%3E%3C/pattern%3E%3ClinearGradient id='paint0_linear_6600_2702' x1='178.712' y1='0.335327' x2='178.712' y2='100.335' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_6600_2702' x1='179.141' y1='100.335' x2='179.141' y2='0.335329' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
//     width: 100%;
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//     height: 90px;
//     svg {
//       width: 70px;
//     }
//     img {
//       max-width: 45%;
//     }
//     @media (max-width: 992px) {
//       height: 75px;
//       svg {
//         width: 60px;
//       }
//       img {
//         max-width: 40%;
//       }
//     }
//   }
// }
.token-banner {
  padding: 0px 0 0;
  height: 580px;
  position: relative;
  @media (max-width: 992px) {
    height: auto;
    padding: 30px 0 50px;
  }
  &::before {
    background: url("data:image/svg+xml,%3Csvg width='1440' height='747' viewBox='0 0 1440 747' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.03'%3E%3Crect width='2124' height='2203' rx='1062' transform='matrix(1 0 0 -1 -342 747)' fill='url(%23paint0_linear_4621_1778)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_4621_1778' x1='1062' y1='0' x2='1062' y2='2203' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='1' stop-color='%232F3149' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")
      no-repeat;
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    @media (max-width: 992px) {
      background-size: 170% 180%;
    }
  }
  .banner-content {
    position: relative;
    z-index: 99;
    h1 {
      font-size: 62px;
      font-weight: 700;
      color: #fff;
      line-height: 84px;
      background: linear-gradient(
        270.56deg,
        #be9fff -1.2%,
        #74ffff 29.7%,
        #ffd98e 69.98%,
        #ffbc57 69.99%,
        #ffc169 70%,
        #ff8c73 100.05%
      );

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media (max-width: 1440px) {
        font-size: 52px;
        line-height: 62px;
      }
      @media (max-width: 1200px) {
        font-size: 48px;
        line-height: 56px;
      }
      @media (max-width: 767px) {
        font-size: 36px;
        margin: 0 auto;
        line-height: 42px;
      }
      @media (max-width: 360px) {
        max-width: 85%;
      }
    }
    p {
      font-size: 18px;
      color: #8e93b2;
      max-width: 90%;
      margin: 10px auto;
      @media (max-width: 992px) {
        font-size: 18px;
        max-width: 100%;
        margin: 20px auto;
      }
      &.tagline-content {
        border-radius: 30px;
        font-size: 16px;
        color: #fff;
        background: #252b36;
        padding: 10px 10px 10px 10px;
        @media (max-width: 640px) {
          font-size: 10px;
          max-width: 100%;
        }
        span {
          background: #fff;
          color: #0d0d2b;
          padding: 5px 10px;
          margin-right: 5px;
          border-radius: 30px;
        }
      }
    }
    .button-div {
      .custom-btn {
        background: #1f2233;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.49);
        font-size: 20px;
        padding: 12px 20px;
        font-weight: 500;
        margin: 15px 18px 0 0;
        color: #fff;
        &:hover {
          background: #fff;
          color: #0d0d2b;
        }
        @media (max-width: 767px) {
          font-size: 16px;
          margin: 15px 8px 0 0;
          padding: 9px 18px;
        }
        @media (max-width: 360px) {
          padding: 6px 12px;
          font-size: 14px;
          svg {
            width: 24px;
          }
        }
        &.ghost {
          background: #3671e9;
          border-color: #3671e9;
          color: #fff;
          &:hover {
            background: #fff;
            color: #0d0d2b;
            border-color: #fff;
          }
          @media (max-width: 767px) {
            margin-right: 0;
          }
        }
      }
    }
  }
  // .token-listing {
  //   margin: 50px 0;
  //   li {
  //     flex: 0 0 50%;
  //     max-width: 50%;
  //     padding: 12px 12px;
  //     list-style: none;
  //     .outer-div {
  //       border: 1px solid rgba(255, 255, 255, 0.08);
  //       border-radius: 40px;
  //       padding: 26px;
  //       background: rgba(255, 255, 255, 0.12);
  //       img {
  //         max-width: 50px;
  //         top: -10px;
  //         right: -10px;
  //         background: #2e3138;
  //         border-radius: 50%;
  //       }
  //       p {
  //         font-size: 18px;
  //         margin: 0;
  //         font-weight: 600;
  //         color: #927af4;
  //       }
  //       span {
  //         font-size: 14px;
  //         display: block;
  //         margin: 8px 0;
  //         color: rgba(255, 255, 255, 0.8);
  //       }
  //       h2 {
  //         font-size: 20px;
  //         font-weight: 600;
  //         margin: 0;
  //         color: #fff;
  //       }
  //     }
  //   }
  // }
}

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
  background: rgba(50, 54, 77, 1);
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  margin: auto;
  overflow: hidden;
  padding: 30px 0;
  position: relative;
  max-width: 1920px;
  .slide-track {
    -webkit-animation: scroll 40s linear infinite;
    animation: scroll 40s linear infinite;
    width: calc(250px * 14);
  }
  .slide {
    width: 250px;
  }
}

.faq-section {
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  .heading-title {
    text-align: center;
    h2 {
      font-size: 36px;
      color: #fff;
      display: inline-block;
      font-weight: 700;
      line-height: 42px;
      text-align: center;
      position: relative;
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
  }
  .accordion-flush {
    .accordion-item {
      background: #1c1d29;
      border: 1px solid rgba(255, 255, 255, 0.12);
      margin: 0 0 20px;
      border-radius: 24px;
      .accordion-header {
        .accordion-button {
          border-radius: 24px;
          font-size: 22px;
          padding: 22px 20px 20px;
          font-weight: 500;
          box-shadow: none;
          background: #1c1d29;
          color: #fff;
          &.collapsed {
            padding: 22px 20px;
          }
          @media (max-width: 992px) {
            font-size: 18px;
            padding: 18px 16px;
          }
          &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0124 15.625H39.9874L24.9999 32.7531L10.0124 15.625ZM7.65932 17.6844L22.6468 34.8094C22.9401 35.145 23.3019 35.4141 23.7077 35.5984C24.1136 35.7827 24.5542 35.8781 24.9999 35.8781C25.4457 35.8781 25.8863 35.7827 26.2922 35.5984C26.698 35.4141 27.0598 35.145 27.3531 34.8094L42.3406 17.6844C44.1093 15.6625 42.6718 12.5 39.9874 12.5H10.0124C9.41168 12.4995 8.8235 12.6721 8.31835 12.9973C7.81319 13.3225 7.41247 13.7864 7.16417 14.3334C6.91587 14.8805 6.83052 15.4875 6.91834 16.0818C7.00615 16.6761 7.26342 17.2325 7.65932 17.6844Z' fill='white'/%3E%3C/svg%3E");
            background-size: 28px;
            height: 28px;
            width: 28px;
          }
          &:focus {
            border: 0;
            outline: 0;
            box-shadow: none;
          }
        }
      }
      .accordion-collapse {
        .accordion-body {
          padding-top: 0;
          font-size: 20px;
          color: #758092;
          @media (max-width: 992px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.roadmap-section {
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  .heading-title {
    text-align: center;
    h2 {
      font-size: 36px;
      color: #fff;
      display: inline-block;
      font-weight: 700;
      line-height: 42px;
      text-align: center;
      position: relative;
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
  }
  .roadmap-container {
    max-width: 900px;
    position: relative;
    @media (max-width: 992px) {
      max-width: 100%;
    }
    &:before {
      content: "";
      height: calc(100% - 140px);
      width: 1px;
      display: block;
      position: absolute;
      left: 50%;
      top: 100px;
      background: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FFFFFF66' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      @media (max-width: 992px) {
        left: 20px;
        height: 100%;
        top: 0;
      }
    }
    .roadmap-row {
      margin: 0 0 40px;
      &:last-child {
        margin: 0;
      }
      @media (max-width: 992px) {
        flex-wrap: wrap;
        margin: 0 0 20px;
      }
      &.mobile-reverse {
        .right-col {
          &.title-div {
            @media (max-width: 992px) {
              order: 1;
            }
          }
        }
        .left-col {
          &.listing-div {
            @media (max-width: 992px) {
              order: 2;
            }
          }
        }
      }
      .left-col {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 50px;
        @media (max-width: 992px) {
          max-width: 100%;
          padding-right: 0;
          flex: 0 0 100%;
        }
        &.title-div {
          position: relative;
          text-align: right;
          @media (max-width: 992px) {
            text-align: left;
          }
          &.active {
            &:before {
              background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle opacity='0.1' cx='12' cy='12' r='11.5' stroke='white'/%3E%3Ccircle cx='12' cy='12' r='8' fill='white'/%3E%3C/svg%3E")
                no-repeat;
              right: calc(-100% - -12px);
              top: 10px;
              @media (max-width: 992px) {
                right: -8px;
              }
            }
          }
          &::before {
            content: "";
            background: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='21' cy='21' r='21' fill='%23101123'/%3E%3Ccircle cx='21' cy='21' r='11.5' stroke='%23272939'/%3E%3Ccircle cx='21' cy='21' r='9.5' fill='%233F4160' stroke='%23101123' stroke-width='3'/%3E%3C/svg%3E")
              no-repeat;
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            right: calc(-100% - -20px);
            top: 0px;
            @media (max-width: 992px) {
              right: 0;
            }
          }
          &:after {
            content: "";
            width: 35px;
            height: 1px;
            display: inline-block;
            background: rgba(255, 255, 255, 0.6);
            position: absolute;
            top: 22px;
            @media (max-width: 992px) {
              display: none;
            }
          }
          h3 {
            font-size: 20px;
            text-transform: uppercase;
            margin: 0;
          }
        }
        &.listing-div {
          text-align: right;
          @media (max-width: 992px) {
            text-align: left;
          }
          ul {
            li {
              margin: 5px 0;
              text-align: left;
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
      }
      .right-col {
        padding: 0 50px;
        flex: 0 0 50%;
        max-width: 50%;
        @media (max-width: 992px) {
          max-width: 100%;
          flex: 0 0 100%;
          padding-right: 0;
        }
        &.title-div {
          position: relative;

          &.active {
            &:before {
              background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle opacity='0.1' cx='12' cy='12' r='11.5' stroke='white'/%3E%3Ccircle cx='12' cy='12' r='8' fill='white'/%3E%3C/svg%3E")
                no-repeat;
              right: 12px;
              top: 10px;
              @media (max-width: 992px) {
                right: -8px;
              }
            }
          }
          &::before {
            content: "";
            background: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='21' cy='21' r='21' fill='%23101123'/%3E%3Ccircle cx='21' cy='21' r='11.5' stroke='%23272939'/%3E%3Ccircle cx='21' cy='21' r='9.5' fill='%233F4160' stroke='%23101123' stroke-width='3'/%3E%3C/svg%3E")
              no-repeat;
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            right: 20px;
            top: 0px;
            @media (max-width: 992px) {
              right: 0;
            }
          }
          &:after {
            content: "";
            width: 35px;
            height: 1px;
            display: inline-block;
            background: rgba(255, 255, 255, 0.6);
            position: absolute;
            left: 15px;
            top: 22px;
            @media (max-width: 992px) {
              display: none;
            }
          }
          h3 {
            font-size: 20px;
            text-transform: uppercase;
            margin: 0;
          }
        }
      }
      .box-div {
        border-radius: 10px;
        display: inline-flex;
        padding: 10px 18px;
        border: 1px solid #42466a;
        color: #fff;
        @media (max-width: 992px) {
          margin: 0 0 20px;
        }
      }
    }
  }
}

.team-section {
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  .heading-title {
    text-align: center;
    h2 {
      font-size: 36px;
      color: #fff;
      display: inline-block;
      font-weight: 700;
      line-height: 42px;
      text-align: center;
      position: relative;
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
  }
  .team-listing {
    ul {
      flex-wrap: wrap;
      li {
        max-width: 25%;
        flex: 0 0 25%;
        padding: 10px 10px;
        margin: 0;
        list-style:none;
        @media (max-width: 992px) {
          max-width: 50%;
          flex: 0 0 50%;
        }
        @media (max-width: 640px) {
          max-width: 100%;
          flex: 0 0 100%;
        }
        &:nth-child(1) {
          max-width: 50%;
          flex: 0 0 50%;
          @media (max-width: 992px) {
            max-width: 100%;
            flex: 0 0 100%;
          }
          .team-outer {
            padding-left: 30px;
            &:before {
              content: "";
              height: 100%;
              width: 12px;
              position: absolute;
              border-radius: 20px 0px 0 20px;
              display: block;
              left: 0;
              right: 0;
              background: #1cb0c1;
            }
            .team-body {
              ul {
                li {
                  max-width: 100%;
                  flex: 0 0 100%;
                  color: #fff;
                  font-size: 14px;
                  padding: 3px 10px;
                  list-style: disc;
                }
              }
            }
          }
        }
        &:nth-child(2) {
          .team-outer {
            &:before {
              background-color: #ffb23d;
            }
          }
        }
        &:nth-child(3) {
          .team-outer {
            &:before {
              background-color: #e94e1b;
            }
          }
        }
        &:nth-child(4) {
          .team-outer {
            &:before {
              background-color: #7e57d2;
            }
          }
        }
        &:nth-child(5) {
          .team-outer {
            &:before {
              background-color: #95c11f;
            }
          }
        }
        &:nth-child(6) {
          .team-outer {
            &:before {
              background-color: #ffc076;
            }
          }
        }
        &:nth-child(7) {
          .team-outer {
            &:before {
              background-color: #16a5f5;
            }
          }
        }
        .team-outer {
          background: #1b202b;
          margin: 0;
          border: 1px solid #384a5c;
          border-radius: 20px;
          padding: 20px;
          text-align: center;
          position: relative;
          @media (max-width: 1200px) {
            padding: 10px;
          }
          &:before {
            content: "";
            height: 12px;
            width: 100%;
            position: absolute;
            top: 0;
            border-radius: 20px 20px 0 0;
            display: block;
            left: 0;
            right: 0;
          }
          .team-header {
            margin: 0 0 10px;
            padding: 0 0 10px;
            position: relative;
            &:before {
              content: "";
              background: #22303e80;
              width: calc(100% - 50px);
              display: block;
              position: absolute;
              height: 1px;
              left: 0;
              right: 0;
              margin: auto;
              bottom: 0;
            }
            h2 {
              font-size: 22px;
              color: #fff;
              margin: 10px 0 0;
            }
            p {
              font-size: 14px;
              margin: 0;
              color: #fff;
            }
          }
          .team-body {
            p {
              font-size: 14px;
              margin: 0;
              min-height: 70px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.marketing-section {
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  .heading-title {
    text-align: center;
    h2 {
      font-size: 36px;
      color: #fff;
      display: inline-block;
      font-weight: 700;
      line-height: 42px;
      text-align: center;
      position: relative;
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
  }
  .marketing-content {
    p {
      font-size: 18px;
      margin: 0 0 20px;
      color: #a5aebf;
    }
  }
  .button-div {
    .custom-button {
      background: #1f2233;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.49);
      font-size: 20px;
      padding: 12px 20px;
      font-weight: 500;
      margin: 15px 18px 0 0;
      color: #fff;
      &:hover {
        background: #fff;
        color: #0d0d2b;
      }
      @media (max-width: 767px) {
        font-size: 16px;
        margin: 15px 0px 0 0;
        padding: 9px 18px;
      }
      @media (max-width: 360px) {
        padding: 6px 12px;
        font-size: 14px;
        svg {
          width: 24px;
        }
      }
      &.ghost {
        background: #3671e9;
        border-color: #3671e9;
        color: #fff;
        &:hover {
          background: #fff;
          color: #0d0d2b;
          border-color: #fff;
        }
        @media (max-width: 767px) {
          margin-right: 0;
        }
      }
    }
  }
}
